<template>
  <div>
    <PageHeader :title="title" :items="items" />
    <div>
      <!-- Main Card -->
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <!-- Filter and Per Page Options -->
              <div class="row mt-2 d-flex justify-content-between mx-3">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>

                <!-- Data Table -->
                <div class="table-responsive mb-0">
                  <b-table
                    :items="creditsDetails.data"
                    :fields="colfields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    ref="adminTable"
                    show-empty
                  >
                  </b-table>
                </div>
                <!-- Pagination -->
                <div class="col-12 border-top pt-3">
                  <div class="d-flex justify-content-end">
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="filteredData.length"
                      :per-page="perPage"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import _ from "lodash";
import { eventBus } from "../../../../main";

export default {
  data() {
    return {
      creditsDetails: [],
      filteredData: [],
      title: "Total Credits Usage",
      items: [
        {
          text: "Dashboard",
          href: `/dashboard`,
        },
        {
          text: "Total Credits Usage",
          active: true,
        },
      ],
      userId: this.$store.state.Auth.userId,
      gitToken: this.$store.state.Auth.gitToken,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "",
      sortDesc: true,
      colfields: this.generateTableColumns(),
    };
  },
  computed: {
    rows() {
      return this.creditsDetails.data.length;
    },
    _() {
      return _;
    },
    totalPages() {
      return Math.ceil(this.rows / this.perPage);
    },
  },
  watch: {
    perPage: {
      handler() {
        this.currentPage = 1;
      },
    },
    creditsDetails: {
      handler() {
        this.filteredData = this.creditsDetails.data;
      },
    },
  },
  created() {
    eventBus.$emit("update-sidebar", "menuitems.dashboard.text");
  },
  mounted() {
    if (this.$store.state.Auth.creditsDetails) {
      this.creditsDetails = this.$store.state.Auth.creditsDetails;
    } else {
      this.getCreditsDetails(); // Fetch recent publications
    }
  },

  methods: {
    moment,
    onFiltered(filteredItems) {
      // Update the totalRows to the length of the filtered items
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onPageChange(page) {
      this.currentPage = page;
    },

    generateTableColumns() {
      return [
        {
          key: "docMigrationType.text",
          label: "Migration Type",
          sortable: true,
        },
        {
          key: "fileName",
          label: "File Name",
          sortable: true,
        },
        {
          key: "pagesConverted",
          label: "Credits Usage",
          sortable: true,
        },

        {
          key: "convertedAt",
          label: "Migrated At",
          sortable: true,
          sortByFormatted: true,
          formatter: (value, key, item) =>
            moment(item.convertedAt).format("DD-MM-YYYY hh:mm A"),
        },
      ];
    },
    // This method is use to generate error or success message
    messageToast(messageToastTitle, messageToastVariant, messageToastContent) {
      this.$bvToast.toast(messageToastContent, {
        title: messageToastTitle,
        variant: messageToastVariant,
        solid: true,
      });
    },

    getCreditsDetails() {
      this.$store.getters.client
        .get(`/orguser/credits-info`)
        .then((response) => {
          this.creditsDetails = response.data;
          this.$store.commit("setCreditsDetails", response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.custom-input-box {
  border: 1px solid #ced4da !important;
  border-radius: 0px;
  background-color: #ffffff !important;
}

.export-button {
  height: 38px;
}

.search-box input {
  height: 38px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .row-count-cust-cls {
    margin-bottom: 10px;
  }

  .row-pagination-cust-cls {
    margin-top: 20px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .row-count-cust-cls ul {
    margin-bottom: 10px;
    text-align: left !important;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
</style>
