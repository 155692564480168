var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-modal',{ref:"modaloutputprogress",attrs:{"id":"modal-progress","title":"Processing","title-class":"font-18","hide-header":"","hide-footer":"","hide-close":"","no-close-on-backdrop":"","no-close-on-esc":""}},[_c('strong',[_vm._v("Please wait")]),_c('br'),_c('p',[_vm._v("Document publishing is currently in progress.")]),_c('b-progress',{attrs:{"value":75,"max":100,"animated":""}})],1),_c('b-modal',{ref:"modalcommit",attrs:{"id":"modal-commit","title":"Commit Output","title-class":"font-18","hide-header":"","hide-footer":"","hide-close":"","no-close-on-backdrop":"","no-close-on-esc":""},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('div',[_c('b-form-group',{attrs:{"label":"Commit Message"}},[_c('b-form-input',{attrs:{"for":"text"},model:{value:(_vm.commitMsg),callback:function ($$v) {_vm.commitMsg=$$v},expression:"commitMsg"}})],1),_c('button',{staticClass:"btn btn-primary btn-sm",on:{"click":function($event){return _vm.commitOutput()}}},[_vm._v(" Commit ")])],1)]),(_vm.hideform)?_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"bg-primary text-white"}),_c('div',{staticClass:"card shadow-sm"},[_c('div',{staticClass:"card-body text-center"},[_c('h5',{staticClass:"mb-4"},[_vm._v("Project Name : "+_vm._s(_vm.projectName))]),_c('p',[_vm._v("Important! Commit changes to download the output.")]),_c('div',{staticClass:"d-flex justify-content-center pt-1"},[_c('button',{staticClass:"btn btn-primary btn-sm mr-2 mb-2",attrs:{"disabled":_vm.disablecommitbutton},on:{"click":function($event){$event.preventDefault();return _vm.githubCommit.apply(null, arguments)}}},[_vm._v(" Commit on GitHub ")]),_c('button',{staticClass:"btn btn-light btn-sm mr-2 mb-2",on:{"click":function($event){return _vm.downloadFun()}}},[_vm._v(" Download Output ")]),(
                _vm.ditaOtFolder[_vm.selectedOutputFormatIndex].transtype ===
                'metrhtml5'
              )?_c('button',{staticClass:"btn btn-info btn-sm mr-2 mb-2",attrs:{"disabled":!_vm.userActivityId},on:{"click":function($event){return _vm.deployToChatbotSelectBranch()}}},[_vm._v(" Deploy to chatbot ")]):_vm._e()])])])])]):_vm._e(),(!_vm.hideform)?_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"form-group"},[_vm._m(0),_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"},{name:"model",rawName:"v-model",value:(_vm.typeform.releaseTitle),expression:"typeform.releaseTitle"}],staticClass:"form-control",class:{
              'is-invalid': _vm.submitted && _vm.$v.releaseTitle.$error,
            },attrs:{"required":"","name":"releaseTitle","type":"text","id":"releaseTitle","placeholder":"Enter Release Title"},domProps:{"value":(_vm.typeform.releaseTitle)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;return _vm.preventLeadingSpace.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.typeform, "releaseTitle", $event.target.value)}}}),_c('p',{directives:[{name:"show",rawName:"v-show",value:(
              _vm.errors.has('releaseTitle') &&
              _vm._.find(_vm.errors.items, { field: 'releaseTitle' }).rule ==
                'required'
            ),expression:"\n              errors.has('releaseTitle') &&\n              _.find(errors.items, { field: 'releaseTitle' }).rule ==\n                'required'\n            "}],staticClass:"text-danger text-sm"})]),_c('div',{staticClass:"form-group"},[_vm._m(1),_c('div',[_c('select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"},{name:"model",rawName:"v-model",value:(_vm.typeform.inputPath),expression:"typeform.inputPath"}],staticClass:"form-control",attrs:{"required":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.typeform, "inputPath", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"","disabled":"","selected":""}},[_vm._v(" Select Source Ditamap ")]),_vm._l((_vm.selectInput),function(data){return _c('option',{key:data.path,domProps:{"value":data.path}},[_vm._v(" "+_vm._s(data.fileName)+" ")])})],2),_c('p',{directives:[{name:"show",rawName:"v-show",value:(
                _vm.errors.has('typeform.inputPath') &&
                _vm.$v.typeform.inputPath.required
              ),expression:"\n                errors.has('typeform.inputPath') &&\n                $v.typeform.inputPath.required\n              "}],staticClass:"text-danger text-sm"},[_c('span',[_vm._v("Input Format selection is required")])])])]),_c('div',{staticClass:"form-group"},[_vm._m(2),_c('div',[_c('select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"},{name:"model",rawName:"v-model",value:(_vm.selectedOutputFormatIndex),expression:"selectedOutputFormatIndex"}],staticClass:"form-control",attrs:{"required":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedOutputFormatIndex=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"","disabled":"","selected":""}},[_vm._v("Select Output Format")]),_vm._l((_vm.selectOutputFormat),function(data,index){return _c('option',{key:data.id,domProps:{"value":index}},[_vm._v(" "+_vm._s(data.transtype)+" ")])})],2),_c('p',{directives:[{name:"show",rawName:"v-show",value:(
                _vm.errors.has('typeform.outputFormat') &&
                _vm.$v.typeform.outputFormat.required
              ),expression:"\n                errors.has('typeform.outputFormat') &&\n                $v.typeform.outputFormat.required\n              "}],staticClass:"text-danger text-sm"},[_c('span',[_vm._v("Output Format selection is required")])])])]),_c('div',{staticClass:"form-group mb-0 text-right"},[_c('div',[_c('button',{staticClass:"btn btn-sm btn-primary",attrs:{"disabled":!(_vm.typeform.inputPath && _vm.typeform.releaseTitle)},on:{"click":function($event){return _vm.generateOutputFun(_vm.projectPath, _vm.workspacePath)}}},[_vm._v(" Transform ")]),_c('button',{staticClass:"btn btn-light btn-sm m-l-5 ml-1",on:{"click":_vm.resetform}},[_vm._v(" Cancel ")])])])])])]):_vm._e(),_c('DeployToChatbot',{ref:"deplotToChatbotRef",attrs:{"userActivityId":_vm.userActivityId,"repouser":_vm.repouser},on:{"deleteUserActivityId":_vm.deleteUserActivityId}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',[_vm._v("Title "),_c('span',{staticClass:"text-secondary"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',[_vm._v("Input Source Ditamap "),_c('span',{staticClass:"text-secondary"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',[_vm._v("Output Format "),_c('span',{staticClass:"text-secondary"},[_vm._v("*")])])
}]

export { render, staticRenderFns }