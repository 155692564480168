<template>
  <div>
    <!-- Display a page header with title and breadcrumb items -->
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-md-8 col-sm-12 mb-3">
        <InfoCard />
        <div class="card mb-0">
          <div class="card-body">
            <Chart />
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-12 mb-3">
        <RecentNotification class="h-100" />
      </div>
      <!-- <div class="col-12 mb-3">
        <RecentActivities />
      </div>
      <div class="col-md-6 col-sm-12 mb-3">
        <RecentPublication class="m-0 p-0" />
      </div> -->
    </div>
    <b-card>
      <b-tabs
        justified
        nav-class="nav-tabs-custom"
        content-class="text-muted"
        class="btabs-height"
      >
        <b-tab title="Recent Activities" active>
          <div>
            <div>
              <RecentActivities />
            </div>
          </div>
        </b-tab>
        <b-tab title="Recent Release Log">
          <div>
            <div>
              <RecentPublication class="m-0 p-0" />
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import RecentNotification from "./components/recentnotification.vue";
import RecentPublication from "./components/recentpublication.vue";
import Chart from "./components/chart.vue";
import InfoCard from "./components/InfoCard.vue";
import RecentActivities from "./components/recentActivities.vue";
import { eventBus } from "../../../main";

export default {
  name: "Dashboard",

  components: {
    RecentNotification,
    RecentPublication,
    Chart,
    InfoCard,
    RecentActivities,
  },
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "Dashboard",
          active: true,
        },
      ],
    };
  },
  mounted() {
    // Emit an event to update the sidebar with the current menu item
    eventBus.$emit("update-sidebar", "menuitems.dashboard.text");
  },
};
</script>

<style scoped>
/* Remove the unnecessary styling for .column */
@media (max-width: 991px) {
  .recPubl {
    order: 3;
  }
}
</style>
