<template>
  <div style="height: 320px">
    <apexchart
      v-if="!isFetching"
      type="area"
      :options="chartOptions"
      :series="chartSeries"
      height="320"
    />
    <div v-if="isChartLoading">
      <b-overlay show variant="light"
        ><div style="height: 320px"></div
      ></b-overlay>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      userId: this.$store.state.Auth.userId,
      releases: [],
      isFetching: false,
      chartOptions: {
        chart: {
          type: "area",
          // height: 300,
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          type: "datetime", // Treat X-axis as datetime
          title: {
            text: "Date & Time",
          },
        },
        yaxis: {
          title: {
            text: "Release Count",
          },
        },
        title: {
          text: "Releases by Output Format",
        },
        stroke: {
          curve: "smooth",
          width: "2",
        },
        fill: {
          opacity: 0.3,
        },
        colors: ["#FF5733", "#33FF57", "#3357FF", "#FF33A1"],
        legend: {
          position: "top",
        },
        tooltip: {
          x: {
            format: "yyyy-MM-dd HH:mm", // Full date-time format in tooltip
          },
          theme: "dark",
          fillSeriesColor: false,
        },
        noData: {
          text: "No Releases Available", // Message to display when there is no data
          align: "center", // Align the message in the center
          verticalAlign: "middle", // Vertically align the message
          offsetX: 0, // Horizontal offset (optional)
          offsetY: 0, // Vertical offset (optional)
          style: {
            fontSize: "16px", // Font size for the message
            fontFamily: "Helvetica, Arial, sans-serif", // Font family for the message
            color: "#777", // Color of the message
          },
        },
      },
      chartSeries: [],
    };
  },
  mounted() {
    // Check if recent publications data is available in the store, if not, fetch data
    this.isFetching = true;
    if (this.$store.state.Auth.recentPublicationsData.length) {
      this.releases = this.$store.state.Auth.recentPublicationsData;
      console.log("called direct", this.releases);
      this.prepareChartData(this.$store.state.Auth.recentPublicationsData);
    } else {
      console.log("called api ");

      this.getReleaseNote(); // Fetch recent publications
    }
  },
  methods: {
    messageToast(messageToastTitle, messageToastVariant, messageToastContent) {
      this.$bvToast.toast(messageToastContent, {
        title: messageToastTitle,
        variant: messageToastVariant,
        solid: true,
      });
    },
    prepareChartData(releases) {
      console.log("📢[chart.vue:73]: releases: ", releases);
      const groupedData = {};

      // Group releases by output format and time intervals (hourly or 30 minutes)
      releases.forEach((release) => {
        const outputFormat = release.outputFormat;
        const releaseDate = new Date(release.createdAt);
        // const dateKey = releaseDate.toISOString().split("T")[0]; // Get date part (e.g., '2024-11-22')

        // Create a time-based key for each 30-minute interval
        const intervalKey = new Date(
          Math.floor(releaseDate.getTime() / (30 * 60 * 1000)) *
            (30 * 60 * 1000)
        ).toISOString(); // Round down to nearest 30-minute interval

        // Initialize data structure if not yet created
        if (!groupedData[outputFormat]) {
          groupedData[outputFormat] = {};
        }
        if (!groupedData[outputFormat][intervalKey]) {
          groupedData[outputFormat][intervalKey] = 0;
        }

        // Increment the count for this interval and output format
        groupedData[outputFormat][intervalKey]++;
      });

      // Get all unique intervals from the releases (sorted)
      const allIntervals = Array.from(
        new Set(
          releases.map((release) => {
            const releaseDate = new Date(release.createdAt);
            return new Date(
              Math.floor(releaseDate.getTime() / (30 * 60 * 1000)) *
                (30 * 60 * 1000)
            ).toISOString(); // Round down to nearest 30-minute interval
          })
        )
      ).sort();

      // Prepare series data for the chart
      this.chartSeries = Object.keys(groupedData).map((outputFormat) => ({
        name: outputFormat,
        data: allIntervals.map((interval) => {
          // For each interval, get the count or 0 if there is no release for that interval
          return groupedData[outputFormat][interval] || 0;
        }),
      }));

      // Set the X-axis categories as the sorted intervals
      this.chartOptions.xaxis.categories = allIntervals;
      console.log(
        "📢[chart.vue:123]: this.chartOptions.xaxis.categories: ",
        this.chartOptions.xaxis.categories
      );
      this.isFetching = false;
    },
    // Fetch recent publications
    getReleaseNote() {
      this.$store.getters.client
        .get(`/orguser/release/byuserId?userId=${this.userId}`)
        .then((response) => {
          if (response.data) {
            this.releases = response.data;
            this.$store.commit("setRecentPublicationsData", response.data);
            this.prepareChartData(response.data);
          }
        })

        .catch(() => {})
        .finally(() => {
          this.isFetching = false;
        });
    },

    setData() {},
  },
};
</script>

<style scoped>
/* Optional custom styles */
</style>
