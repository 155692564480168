import CryptoJS from "crypto-js";
import { secretKey } from "../api/global.env";
const encodeParam = (param) => {
  const encryptedData = CryptoJS.AES.encrypt(param, secretKey).toString();
  return encodeURIComponent(encryptedData);
};

const decryptParam = (param) => {
  // Decode the encrypted data first
  const encryptedData = decodeURIComponent(param);
  const decodedParam = CryptoJS.AES.decrypt(encryptedData, secretKey).toString(
    CryptoJS.enc.Utf8
  );
  return decodedParam;
};

export { encodeParam, decryptParam };
