<template>
  <div>
    <PageHeader :title="title" :items="items" />
    <div>
      <!-- Main Card -->
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <!-- Filter and Per Page Options -->
              <div class="row mt-2 d-flex justify-content-between mx-3">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
                <div
                  class="d-md-flex align-items-center justify-content-between mb-3"
                >
                  <div class="search-box mr-3 mb-2">
                    <div class="position-relative">
                      <input
                        type="text"
                        class="form-control text-primary rounded custom-input-box"
                        placeholder="Search"
                        id="myInput"
                        v-model="filter"
                      />
                      <i class="mdi mdi-magnify search-icon"></i>
                    </div>
                  </div>
                  <!-- Buttons -->
                  <b-button
                    size="sm"
                    class="export-button mr-2 mb-2"
                    variant="light"
                    @click="exportToExcel"
                    >Export to Excel</b-button
                  >
                  <b-button
                    size="sm"
                    class="export-button mb-2"
                    variant="light"
                    @click="exportToCSV"
                    >Export to CSV</b-button
                  >
                </div>

                <!-- Data Table -->
                <div class="table-responsive mb-0">
                  <b-table
                    :items="activityData"
                    :fields="colfields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    :filter-function="filterTable"
                    ref="adminTable"
                    show-empty
                  >
                    <!-- Custom cell rendering for createdAt -->
                    <template #cell(createdAt)="row">
                      {{
                        moment(row.item.createdAt).format("YYYY-MM-DD h:mm A")
                      }}
                    </template>
                    <template #cell(delete)="row">
                      <div @click="deleteRow(row.item)">
                        <i class="btn text-secondary mdi mdi-delete"></i>
                      </div>
                    </template>
                    <template #cell(download)="row">
                      <b-button
                        size="sm"
                        variant="primary"
                        @click="downloadFun(row.item)"
                        ><i class="mdi mdi-download"></i
                      ></b-button>
                    </template>
                  </b-table>
                </div>
                <!-- Pagination -->
                <div class="col-12 border-top pt-3">
                  <div class="d-flex justify-content-end">
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="filteredData.length"
                      :per-page="perPage"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import _ from "lodash";
import * as XLSX from "xlsx";
import { eventBus } from "../../../main";
import Swal from "sweetalert2";
import downloadSourceFiles from "../../../services/download-source-files";

export default {
  data() {
    return {
      activityData: [],
      filteredData: [],
      title: "Recent Publication ",
      items: [
        {
          text: "Dashboard",
          href: `/dashboard`,
        },
        {
          text: "Recent Publications",
          active: true,
        },
      ],
      userId: this.$store.state.Auth.userId,
      gitToken: this.$store.state.Auth.gitToken,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "",
      sortDesc: true,
      colfields: this.generateTableColumns(),
      chartData: {
        labels: [],
        datasets: [
          {
            label: "Number of Releases",
            backgroundColor: "#f87979",
            data: [],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  computed: {
    rows() {
      return this.activityData.length;
    },
    _() {
      return _;
    },
    totalPages() {
      return Math.ceil(this.rows / this.perPage);
    },
  },
  watch: {
    perPage: {
      handler() {
        this.currentPage = 1;
      },
    },
    activityData: {
      handler() {
        this.filteredData = this.activityData;
      },
    },
    filter: {
      handler() {
        this.filteredData = this.activityData?.filter((data) =>
          data.projectName.toUpperCase().includes(this.filter?.toUpperCase())
        );
      },
    },
  },
  created() {
    eventBus.$emit("update-sidebar", "menuitems.dashboard.text");
    if (this.$store.state.Auth.recentPublicationsData.length) {
      this.activityData = this.$store.state.Auth.recentPublicationsData;
    } else {
      this.getReleasePublication();
    }
    this.prepareChartData();
  },

  methods: {
    moment,
    onFiltered(filteredItems) {
      // Update the totalRows to the length of the filtered items
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onPageChange(page) {
      this.currentPage = page;
    },
    prepareChartData() {
      const projects = {};
      // Count releases per project
      this.activityData.forEach((release) => {
        const projectName = release.projectName;
        projects[projectName] = (projects[projectName] || 0) + 1;
      });
      // Populate chart data
      this.chartData.labels = Object.keys(projects);
      this.chartData.datasets[0].data = Object.values(projects);
      // Update the chart
      this.$refs.barChart._chart.update();
    },
    generateTableColumns() {
      return [
        {
          key: "releaseTitle",
          label: "Release Title",
          sortable: true,
        },
        {
          key: "projectName",
          label: "Project Name",
          sortable: true,
        },
        {
          key: "releasedBy",
          label: "Released By",
          sortable: true,
        },
        {
          key: "outputFormat",
          label: "Output Format",
          sortable: true,
        },
        {
          key: "ditaotVersion",
          label: "DITA OT Version",
          sortable: true,
        },
        {
          key: "createdAt",
          label: "Created At",
          sortable: true,
          sortByFormatted: true,
          formatter: (value, key, item) =>
            moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss"),
        },
        {
          key: "delete",
          label: "Delete",
          sortable: false,
        },
        {
          key: "download",
          label: "Download",
          sortable: false,
        },
      ];
    },
    // This method is use to generate error or success message
    messageToast(messageToastTitle, messageToastVariant, messageToastContent) {
      this.$bvToast.toast(messageToastContent, {
        title: messageToastTitle,
        variant: messageToastVariant,
        solid: true,
      });
    },
    deleteRow(item) {
      Swal.fire({
        title: "Delete release",
        text: "Do you want to delete this release?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "delete",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const index = this.activityData.indexOf(item);
          if (index !== -1) {
            this.activityData.splice(index, 1);
            this.$store.commit("setRecentPublicationsData", this.activityData);
            this.deleteItemOnServer(item);
          }
        }
      });
    },
    deleteItemOnServer(item) {
      this.$store.getters.client
        .delete(`/orguser/release?Id=${item._id}`)
        .then(() => {
          this.messageToast("Success", "primary", "Item deleted successfully");
        })
        .catch(() => {
          this.messageToast("Error", "danger", "Failed to delete item");
        });
    },
    getReleasePublication() {
      this.$store.getters.client
        .get(`/orguser/release/byuserId?userId=${this.userId}`)
        .then((response) => {
          if (response.data) {
            this.activityData = response.data;
            this.$store.commit("setRecentPublicationsData", response.data);
          } else {
            this.messageToast(
              "Invalid request",
              "danger",
              "No data received from the server"
            );
          }
        })
        .catch((err) => {
          this.messageToast(
            "Invalid request",
            "danger",
            err.response ? err.response.data.message : "An error occurred"
          );
          this.showSpinner = false;
        });
    },
    // Download all content of repo as a zip
    // async downloadZip(item) {
    // let loader = this.$loading.show({
    //   loader: "dots",
    // });
    // try {
    //   const response = await this.$store.getters.client.get(
    //     `/orguser/release/download?releaseId=${item._id}`,
    //     {
    //       responseType: "arraybuffer",
    //     }
    //   );
    //   const blob = new Blob([response.data], {
    //     type: response.headers["content-type"],
    //   });
    //   const url = window.URL.createObjectURL(blob);
    //   const link = document.createElement("a");
    //   link.href = url;
    //   link.setAttribute("download", `${item.releaseTitle}.zip`);
    //   document.body.appendChild(link);
    //   link.click();
    //   loader.hide();
    //   this.messageToast("Success", "primary", "Downloaded successfull!");
    // } catch (error) {
    //   loader.hide();
    //   this.messageToast("Invalid request", "danger", error.message);
    // }
    // },
    async fetchRepoInfo(repo) {
      try {
        const response = await fetch(
          `https://api.github.com/repos/${repo}`,
          this.gitToken
            ? {
                headers: {
                  Authorization: `Bearer ${this.gitToken}`,
                },
              }
            : {}
        );
        if (!response.ok) {
          throw new Error(`HTTP ${response.statusText}`);
        }
        return response.json();
      } catch (error) {
        // Handle errors and display an error message
        this.messageToast(
          "Invalid request",
          "danger",
          "Error fetching repository info"
        );
        throw error; // Re-throw the error to be handled at a higher level if needed
      }
    },

    async downloadFun(item) {
      console.log("📢[releasetable.vue:389]: item: ", item);

      let loader = this.$loading.show({
        loader: "dots",
      });
      try {
        await downloadSourceFiles({
          repoName: item.projectName,
          commitSHA: item.commitSHA,
        });
        this.messageToast(
          "Files downloaded",
          "primary",
          "Downloaded successful!"
        );
      } catch (error) {
        this.messageToast("Invalid request", "danger", error.message);
      } finally {
        loader.hide();
      }
    },
    exportToExcel() {
      const dataToExport = this.filteredData.map((item) => ({
        "Release Title": item.releaseTitle,
        "Project Name": item.projectName,
        "Released By": item.releasedBy,
        "Output Format": item.outputFormat,
        "DITA OT Version": item.ditaotVersion,
        "Created At": moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss"),
      }));
      const ws = XLSX.utils.json_to_sheet(dataToExport);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      // Save the workbook as an Excel file
      XLSX.writeFile(wb, "table_data.xlsx");
    },
    exportToCSV() {
      const dataToExport = this.filteredData.map((item) => ({
        "Release Title": item.releaseTitle,
        "Project Name": item.projectName,
        "Released By": item.releasedBy,
        "Output Format": item.outputFormat,
        "DITA OT Version": item.ditaotVersion,
        "Created At": moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss"),
      }));
      const ws = XLSX.utils.json_to_sheet(dataToExport);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      // Save the workbook as a CSV file
      XLSX.writeFile(wb, "table_data.csv", {
        bookType: "csv",
      });
    },
    filterTable(row, filter) {
      const filterUpperCase = filter.toUpperCase();
      const hasNameMatch = row.projectName
        .toUpperCase()
        .includes(filterUpperCase);

      return hasNameMatch;
    },
  },
};
</script>

<style scoped>
.custom-input-box {
  border: 1px solid #ced4da !important;
  border-radius: 0px;
  background-color: #ffffff !important;
}

.export-button {
  height: 38px;
}

.search-box input {
  height: 38px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .row-count-cust-cls {
    margin-bottom: 10px;
  }

  .row-pagination-cust-cls {
    margin-top: 20px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .row-count-cust-cls ul {
    margin-bottom: 10px;
    text-align: left !important;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
</style>
