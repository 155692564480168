<template>
  <div class="">
    <div class="">
      <!-- <div class="custom-title mb-2">Recent Activities</div> -->
      <b-table
        :items="activityData"
        :fields="colFields"
        responsive="sm"
        ref="adminTable"
        show-empty
      >
        <template #cell(activity)="row">
          <div class="">
            <div class="">
              <p class="">
                {{ row.item.activity.name }}
                <span
                  style="font-weight: bold"
                  v-if="row.item.activity.subModule"
                  >({{ row.item.activity.subModule }})</span
                >
              </p>
            </div>
          </div>
        </template>
        <template #cell(date)="row">
          {{ moment(row.item.date).format("YYYY-MM-DD/HH:MM A") }}
        </template>
        <template #cell(action)="row">
          <b-dropdown
            right
            toggle-class="arrow-none card-drop"
            variant="white"
            v-if="row.item.isActive"
          >
            <template v-slot:button-content>
              <i class="mdi mdi-dots-vertical"></i>
            </template>

            <!-- item for changing password -->
            <b-dropdown-item
              v-for="(activity, index) in remainingActivitiesRelatedToCurrent(
                row.item.activity.name
              )"
              @click="callToActionRelatedToActivity(row.item, activity)"
              :key="index"
              >{{ activity.name }}</b-dropdown-item
            >
          </b-dropdown>
          <div v-else class="ml-3">
            <span> - </span>
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { encodeParam } from "../../../../services/cryptoFunction";
// import { secretKey } from "../../../../api/global.env";
// import CryptoJS from "crypto-js";
export default {
  data() {
    return {
      activityData: [
        // {
        //   projectName: "test-new-project-3",
        //   owner: "test1-meta-omkar",
        //   branch: "main",
        //   activity: {
        //     name: "DocMigration",
        //     subModule: "HTML to DITA",
        //   },
        //   date: new Date(),
        // },
        // {
        //   projectName: "test-new-project-3",
        //   owner: "test1-meta-omkar",
        //   branch: "main",
        //   activity: {
        //     name: "DocPublisher",
        //     subModule: "html5",
        //   },
        //   date: new Date(),
        // },
      ],
      colFields: [
        {
          key: "projectDetails.projectName",
          label: "Project Name",
        },
        {
          key: "branch",
          label: "Branch",
        },
        {
          key: "activity",
          label: "Activity",
        },
        {
          key: "date",
          label: "Date & Time",
        },
        {
          key: "action",
          label: "Action",
        },
      ],
    };
  },
  mounted() {
    this.getActivityData();
  },
  methods: {
    moment,
    remainingActivitiesRelatedToCurrent(current) {
      const activities = {
        DocMigration: [
          {
            name: "DocManager",
          },
          {
            name: "DocEditor",
          },
          {
            name: "DocPublisher",
          },
        ],
        DocEditor: [
          {
            name: "DocManager",
          },
          {
            name: "DocEditor",
          },
          {
            name: "DocPublisher",
          },
        ],
        DocPublisher: [
          {
            name: "DocManager",
          },
        ],
      };
      return activities[current];
    },
    callToActionRelatedToActivity(project, activity) {
      console.log("object: " + activity.name);
      const functionToCall = {
        DocManager: () => this.redirectToDocManager(project),
        DocEditor: () => this.redirectToDocEditor(project),
        DocPublisher: () => this.redirectToDocPublisher(project),
      };

      functionToCall[activity.name]();
    },

    redirectToDocManager(project) {
      this.$router.push({
        path: `/docmanager/${encodeParam(
          project.projectDetails?.owner
        )}/${encodeParam(
          project.projectDetails?.projectName
        )}?branchName=${encodeParam(project.branch)}`,
      });
    },
    redirectToDocEditor(project) {
      this.$router.push({
        path: `/doceditor?projectName=${encodeParam(
          project.projectDetails?.projectName
        )}&branchName=${encodeParam(project.branch)}`,
      });
    },
    redirectToDocPublisher(project) {
      this.$router.push({
        path: `/docpublisher?projectName=${encodeParam(
          project.projectDetails?.projectName
        )}&branchName=${encodeParam(project.branch)}`,
      });
    },
    getActivityData() {
      this.$store.getters.client
        .get(`/orguser/recent-activities`)
        .then((response) => {
          this.activityData = response.data.data?.filter(
            (activity) => activity?.projectDetails?.projectName
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.custom-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.5px;
  text-align: left;
  color: rgba(23, 35, 61, 1);
}
.view-details-btn {
  background-color: rgba(113, 165, 203, 0.2);
}

.custom-notifications {
  padding: 14px;
  gap: 24px;
}
</style>
