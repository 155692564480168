<template>
  <div style="min-height: 70vh">
    <!-- Page header component with title and icon -->
    <PageHeader :title="title" :items="item" />
    <div>
      <div class="mr-2 dita-ot-cont px-1 mb-4">
        <span class="dita-ot">DITA-OT Version:</span>
        <span class="dita-ot-version ml-2">{{ ditaOtVersions }}</span>
      </div>
    </div>
    <!-- Modal for committing output to GitHub -->
    <b-modal
      id="modal-commit"
      ref="modalcommit"
      title="Commit Output"
      title-class="font-18"
      hide-footer
      hide-close
      no-close-on-backdrop
      no-close-on-esc
    >
      <div>
        <b-form-group label="Commit Message">
          <b-form-input for="text" v-model="commitMsg"></b-form-input>
        </b-form-group>
        <button class="btn btn-sm btn-primary" v-on:click="commitOutput()">
          Commit on GitHub
        </button>
      </div>
    </b-modal>
    <!-- Modal for showing progress -->
    <b-modal
      id="modal-progress"
      ref="modaloutputprogress"
      title="Processing"
      hide-header
      title-class="font-18"
      hide-footer
      hide-close
      no-close-on-backdrop
      no-close-on-esc
    >
      <strong>Please wait</strong>
      <br />
      <p>loading . . .</p>
      <b-progress
        :value="progress"
        :max="100"
        class="custom-progress"
      ></b-progress>
    </b-modal>
    <div class="row justify-content-center">
      <div class="col-md-4 bg-white">
        <!-- GitHub commit form -->
        <div
          class="custom-notifications d-flex justify-content-between align-items-center flex-wrap"
        >
          <div class="custom-title mb-0">Github Commit</div>
        </div>

        <div class="card-body">
          <label>Select Project<span class="text-danger">*</span></label>

          <!-- Multiselect for project selection -->
          <multiselect
            v-model="selectedproject"
            :options="projectlist.map((item) => item.projectName)"
            placeholder="Choose a project"
            :max-height="150"
          >
          </multiselect>
          <div class="form-group">
            <div class="my-2">
              <label class="col-form-label mr-4"
                >Select Branch<span class="text-danger">*</span></label
              >
              <!-- @click="createNewBranchHandler" -->
              <button
                class="btn btn-primary btn-sm"
                @click="$bvModal.show('changeBranch-bv-modal')"
                :disabled="!disabledCommit"
              >
                Create new branch
              </button>
            </div>
            <div class="">
              <multiselect
                v-model="selectedBranch"
                :options="repobranchesdata.map((item) => item.value)"
                placeholder="Choose a branch"
                :max-height="150"
              >
              </multiselect>
              <!-- <select
                class="form-control"
                v-model="selectedBranch"
                placeholder="Select Branch"
                @change="checkoutBranch"
              >
                <option value="" disabled>Please Choose Branch</option>
                <option
                  v-for="(option, index) in repobranchesdata"
                  :key="index"
                  :value="option.value"
                >
                  {{ option.text }}
                </option>
              </select> -->
            </div>
          </div>

          <div class="text-right pt-1">
            <!-- Buttons for committing and syncing projects -->
            <button
              type="submit"
              class="btn btn-light btn-sm mb-2 mr-2"
              @click.prevent="syncprojects"
            >
              Sync
            </button>
            <button
              :disabled="!disabledCommit || !selectedBranch"
              type="submit"
              class="btn btn-primary mb-2 btn-sm"
              @click.prevent="commitOutput"
            >
              Commit
            </button>
          </div>
        </div>
      </div>
    </div>

    <changeBranch
      :branches="repobranchesdata.map((element) => element.value)"
      :createNewBranchHandler="createNewBranchHandler"
      :clone="false"
    />
  </div>
</template>

<script>
import _ from "lodash";
import Multiselect from "vue-multiselect";
import { mapActions } from "vuex";
import Swal from "sweetalert2";

import { eventBus } from "../../../../main";
import { mapGetters } from "vuex";
import CryptoJS from "crypto-js";
import { secretKey } from "../../../../api/global.env";
import githubService from "../../../../services/github";
import changeBranch from "../../../../components/reusables/changeBranch.vue";
export default {
  components: {
    Multiselect,
    changeBranch,
  },

  data() {
    return {
      previousRoute: null,
      ditaotVersion: "",
      progress: 0,
      selectedBranch: "",
      selectedProjectOwner: "",
      previousSelectedBranch: "",
      selectedproject: "",
      projectlist: [],
      repobranchesdata: [],
      commitMsg: null,
      userId: this.$store.state.Auth.userId,
      orgId: this.$store.state.Auth.orgId,
      disabledCommit: false,
      title: "DocMigration",
      migrationType: this.$route.params.migrationType,
      item: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "DocMigration",
          active: true,
        },
      ],
      ignoreNextChange: false, // Flag to ignore next change
    };
  },
  computed: {
    _() {
      return _;
    },
    isCommitButtonDisabled() {
      return this.commitMsg.length === 0;
    },
    ...mapGetters(["ditaOtVersions"]),
  },
  watch: {
    selectedproject: function (newVal) {
      if (newVal) {
        this.disabledCommit = true;
        // When the selected project changes, update related data
        const selectedProject = this.projectlist.find(
          (item) => item.projectName === newVal
        );
        console.log("selected project:", this.projectlist);
        this.selectedProjectOwner = selectedProject.owner;
        this.selectedproject = selectedProject.projectName;
        this.repobranchesdata = [];
        this.getRepoBranch();
      } else {
        this.disabledCommit = false;
      }
    },
    selectedBranch(newVal, oldValue) {
      // Ignore the next change if we're reverting the value
      if (this.ignoreNextChange) {
        this.ignoreNextChange = false; // Reset the flag
        return;
      }
      if (oldValue && newVal) {
        console.log("in brachName: ", newVal, oldValue);
        this.checkoutBranch();
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.previousRoute = from.path;
    });
  },

  mounted() {
    // Emit an event to update the sidebar
    eventBus.$emit("update-sidebar", "menuitems.docmigration.text");
    this.getprojectslist();
  },
  methods: {
    // Display a toast message
    messageToast(messageToastTitle, messageToastVariant, messageToastContent) {
      this.$bvToast.toast(messageToastContent, {
        title: messageToastTitle,
        variant: messageToastVariant,
        solid: true,
      });
    },
    // Call the 'get' action to fetch project details
    ...mapActions({
      get: "userProjectDetails",
    }),

    // Fetch the list of projects
    getprojectslist() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.$store.getters.client
        .get(`/projectuser/byuserid?userId=${this.userId}`)
        .then((response) => {
          if (response.data && Array.isArray(response.data)) {
            this.projectlist = response.data;
          } else {
            this.messageToast(
              "Error",
              "danger",
              "Received invalid data from the server"
            );
          }
          loader.hide();
        })
        .catch((error) => {
          this.messageToast(
            "Error",
            "danger",
            error.response
              ? error.response.data.message
              : "An error occurred while fetching project names."
          );
          loader.hide();
        });
    },

    async getRepoBranch() {
      let loader = this.$loading.show({
        loader: "dots",
      });

      this.$store.getters.client
        .get(
          `orguser/repobranches?repoUser=${this.selectedProjectOwner}&repoName=${this.selectedproject}`
        )
        .then(async (response) => {
          const res = await githubService.currentBranch(this.selectedproject);
          this.selectedBranch = res.data;
          this.previousSelectedBranch = res.data;
          if (response.data && response.data.length > 0) {
            // this.selectedBranch = response.data[0].name;
            this.repobranchesdata = response.data.map((element) => ({
              value: element.name,
              text: element.name,
            }));
          } else {
            // Handle the case when the response is empty or does not contain branches.
            this.messageToast(
              "invalid request",
              "danger",
              "No branches found in the Project."
            );
          }
        })
        .catch((error) => {
          // Handle errors here, e.g., show an error message or log the error.
          this.messageToast(
            "invalid request",
            "danger",
            error.response.data.message
          );
        })
        .finally(() => {
          loader.hide();
        });
    },

    async checkoutBranch() {
      // this.selectedBranch = branch;
      console.log("in checkoutBranch: ", this.selectedBranch);
      // ============checkout the branch============
      const res = await githubService.checkout(
        {
          repoName: this.selectedproject,
          branchName: this.selectedBranch,
        },
        this
      );
      console.log("ckeckout res: ", res);

      if (res === false) {
        this.messageToast(
          "Invalid request",
          "danger",
          "Failed to checkout branch."
        );
        this.selectedBranch = this.previousSelectedBranch;
        this.ignoreNextChange = true; // Set the flag to ignore next change
        return;
      } else {
        this.previousSelectedBranch = this.selectedBranch;
        this.messageToast("Branch checkout", "success", res.message);
      }
      // ============checkout the branch============
    },

    // create new branch
    async createNewBranchHandler({ baseBranchName, newBranchName }) {
      let loadingInstance = this.$loading.show({
        loader: "dots",
      }); // Show loading
      const branches = this.repobranchesdata.map((element) => element.value);
      const res = await githubService.createNewBranch(
        {
          repoName: this.selectedproject,
          clone: false,
          baseBranchName,
          newBranchName,
        },
        branches
      );
      console.log("res", res);
      loadingInstance.hide(); // Hide loading
      this.$bvModal.hide("changeBranch-bv-modal");

      if (res.type === "success") {
        this.getRepoBranch();
        this.messageToast("New branch", "success", res.data.message);
      } else {
        this.messageToast(
          "Failed to create new branch.",
          "danger",
          res.error.response.data.message
        );
      }
    },

    // Show the GitHub commit modal
    gitHubCommit() {
      this.$refs["modalcommit"].show();
    },
    // Commit the selected output to GitHub
    commitOutput() {
      this.progress = 0;

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-primary btn-sm mr-2",
          cancelButton: "btn btn-light btn-sm",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons
        .fire({
          title: "Add description to submit and commit. ",
          input: "text",
          showCancelButton: true,
          confirmButtonText: "Submit",
          showLoaderOnConfirm: true,
          preConfirm: (commitMsg) => {
            return new Promise((resolve, reject) => {
              if (commitMsg.trim() === "") {
                reject(new Error("Please enter a commit message."));
              } else {
                resolve(commitMsg);
              }
            });
          },
          allowOutsideClick: false,
          inputValidator: (value) => {
            return value.trim() !== ""
              ? undefined
              : "Please enter a commit message.";
          },
        })
        .then(({ value: commitMsg }) => {
          if (commitMsg !== undefined) {
            let body = {
              commitFolderName: this.selectedproject,
              commitMessage: commitMsg,
              branchName: this.selectedBranch,
              userActivityId: localStorage.getItem("userActivityId"),
            };
            swalWithBootstrapButtons.fire({
              title: "Commit request in progress...",
              allowOutsideClick: false,
              onOpen: () => {},
            });
            Swal.showLoading();
            this.$store.getters.client
              .post(
                `/orguser/docMigration/commit-to-github?migrationType=${this.migrationType}`,
                body,
                {
                  onUploadProgress: (progressEvent) => {
                    this.progress = Math.round(
                      (progressEvent.loaded / progressEvent.total) * 100
                    );
                  },
                }
              )
              .then((res) => {
                this.showCommitBtn = false;
                swalWithBootstrapButtons.fire({
                  icon: "success",
                  title: "Commit request completed.",
                  text: res.data.message || `Files committed successfully.`,
                });
                this.$router.push({
                  path: this.previousRoute,
                  query: { alreadyCommit: "committed" },
                });
              })
              .catch((err) => {
                swalWithBootstrapButtons.fire({
                  icon: "error",
                  title: "Commit failed!",
                  text: err.response.data.message,
                });
                this.commitMsg = null;
                this.selectedproject = "";
              });
          }
        });
    },
    // Sync projects with GitHub
    syncprojects() {
      this.progress = 0;
      let body = {
        userId: this.userId,
        orgId: this.orgId,
        gitToken: this.$store.state.Auth.gitToken,
        githubUsername: JSON.parse(
          CryptoJS.AES.decrypt(
            localStorage.getItem("githubUsername"),
            secretKey
          ).toString(CryptoJS.enc.Utf8)
        ),
      };
      setInterval(() => {
        if (this.progress < 90) {
          this.progress += 10;
        }
      }, 500);
      this.$refs["modaloutputprogress"].show();
      this.$store.getters.client
        .put(
          `/orguser/workspace/sync?migrationType=${this.migrationType}`,
          body
        )
        .then((response) => {
          this.$refs["modaloutputprogress"].hide();
          this.selectedproject = "";
          this.projectlist = [
            ...this.projectlist,
            ...response.data.syncedProjects,
          ];
          this.messageToast(
            "Sync Complete",
            "primary",
            "Your assigned project list is up to date!"
          );
        })
        .catch(() => {
          this.$refs["modaloutputprogress"].hide();
          this.messageToast(
            "Invalid request",
            "danger",
            "Sorry, we were unable to sync your projects at this time. Please check your network connectivity and try again. If the issue persists, please contact our technical support team for further assistance."
          );
        });
    },
  },
};
</script>

<style scoped>
.custom-source {
  padding: 14px;
  gap: 24px;
}

.custom-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.5px;
  text-align: left;
  color: rgba(23, 35, 61, 1);
}

.project-name {
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(23, 35, 61, 1);
}

.dita-ot-cont {
  margin-top: 0.4rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}

.dita-ot {
  color: rgba(23, 35, 61, 1);
}

.dita-ot-version {
  color: rgba(105, 111, 121, 1);
}

label {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(23, 35, 61, 1);
}
.custom-notifications {
  padding: 14px;
  gap: 24px;
}

.custom-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.5px;
  text-align: left;
  color: rgba(23, 35, 61, 1);
}

.custom-progress {
  height: 5px;
}

.card-body {
  padding-top: 0rem;
}
</style>
