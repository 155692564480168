<template>
  <div>
    <multiselect
      style="width: min(300px, 100%)"
      v-model="selectedFileType"
      :options="migrationsOptions.map((option) => option.text)"
      :max-height="150"
      @input="emitStepChanged"
      placeholder="Select input source type"
      class="custom-multiselect my-4"
    ></multiselect>
    <Steps :currentStep="currentStep" :steps="steps" />
    <div class="row justify-content-center" v-if="!isLogGenerated">
      <div class="col-md-10 col-sm-12 col-lg-7 text-center">
        <div class="card">
          <div class="card-body mx-4">
            <div v-if="currentScreen === screens.default">
              <div class="text-left">
                <div class="custom-title">
                  Upload input source file<span class="text-danger">*</span>
                </div>
              </div>

              <form enctype="multipart/form-data">
                <!-- File drop zone -->
                <div class="row my-4">
                  <div
                    class="col-md-12 offset-md-0 col-lg-12 offset-lg-0 drop-zone"
                    :class="{ dragging: dragging }"
                    @dragover.prevent
                    @dragenter="dragging = true"
                    @dragleave="dragging = false"
                    @drop="handleDrop"
                  >
                    <!-- display when no source type selected -->
                    <div
                      v-if="!selectedFileType"
                      class="m-2 text-center"
                      role="alert"
                    >
                      <img
                        src="../../../../assets/images/upload.svg"
                        class="img-fluid"
                        style="max-width: 100%"
                      />
                      <p class="mt-2 accepted-doc-type">
                        Please select the source format type first.
                      </p>
                    </div>
                    <!-- Displayed when no file is selected -->
                    <div
                      v-if="selectedFileType && !fileSelected"
                      class="text-center"
                      @click="openFileInput"
                    >
                      <img
                        src="../../../../assets/images/upload.svg"
                        class="img-fluid"
                        style="max-width: 100%"
                      />
                      <p class="mt-2 drag-and-drop-text word-break word-wrap">
                        Browse or
                        <span class="text-primary">drag and drop </span> the
                        files here.
                      </p>
                      <p class="mt-2 accepted-doc-type" v-if="!fileName">
                        Accepted file format: {{ fileExtensionsType }} only.
                      </p>
                    </div>
                    <!-- Displayed when file is selected -->
                    <div
                      v-if="selectedFileType && fileSelected"
                      class="alert alert-primary text-left text-wrap m-2"
                      role="alert"
                    >
                      <p class="text-wrap text-break" v-if="fileName">
                        Selected file: {{ fileName }}
                      </p>
                    </div>

                    <input
                      v-if="!fileSelected"
                      type="file"
                      @change="(e) => handleFileInput(e.target.files[0])"
                      class="d-none"
                      ref="fileInput"
                      name="wordFile"
                      :accept="fileExtensionsType"
                    />
                  </div>
                </div>
                <p
                  style="font-size: 12px; text-align: start"
                  class="d-flex justify-content-center align-items-center"
                >
                  <b-form-checkbox
                    id="checkbox-1"
                    v-model="policyCheckbox"
                    name="checkbox-1"
                  >
                    <span class="mt-1 d-inline-block">
                      Refer to

                      <b-link v-b-modal.doc-con-policy
                        ><b> document conversion policy page.</b></b-link
                      >
                    </span>
                  </b-form-checkbox>
                </p>
                <!-- Buttons for uploading and resetting the form -->
                <div class="text-right">
                  <div>
                    <button
                      type="button"
                      class="btn btn-sm btn-primary mr-2"
                      :disabled="!(policyCheckbox && fileSelected)"
                      @click="uploadFileBackend()"
                    >
                      Upload
                    </button>
                    <button
                      type="button"
                      class="btn btn-sm btn-light"
                      @click="cancelForm"
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <div class="form-group" v-if="currentScreen === screens.upload">
              <div>
                <div class="custom-title text-left">
                  Uploading file<span class="text-danger">*</span>
                </div>
              </div>
              <form enctype="multipart/form-data">
                <!-- File drop zone -->
                <div class="showConvertbtn">
                  <div
                    v-if="fileSelected"
                    class="d-flex justify-content-between p-0 m-0"
                  >
                    <p class="text-break" v-if="fileName">{{ fileName }}</p>

                    <p class="text-secondary" v-if="progress === 100">
                      <span class="mdi mdi-check-circle-outline mr-1"></span
                      >Done
                    </p>
                  </div>
                  <b-progress
                    variant="secondary"
                    :value="progress"
                    :max="100"
                    class="custom-height"
                  ></b-progress>
                </div>

                <div class="text-right mt-3">
                  <button
                    class="btn btn-light btn-sm mr-2"
                    @click.prevent="goBack(screens.default)"
                  >
                    Back
                  </button>
                  <button
                    :disabled="this.progress !== 100"
                    class="btn btn-primary btn-sm"
                    @click.prevent="preFlightCheck()"
                  >
                    Pre-flight Check
                  </button>
                </div>
              </form>
            </div>
            <div
              class="form-group"
              v-if="currentScreen === screens.preFlightCheck"
            >
              <div>
                <div class="custom-title text-left">
                  Pre-flight check file<span class="text-danger">*</span>
                </div>
              </div>
              <form enctype="multipart/form-data">
                <div class="showConvertbtn">
                  <div
                    v-if="fileSelected"
                    class="d-flex justify-content-between p-0 m-0"
                  >
                    <p class="text-break" v-if="fileName">{{ fileName }}</p>

                    <p class="text-secondary" v-if="progress === 100">
                      <span class="mdi mdi-check-circle-outline mr-1"></span
                      >Done
                    </p>
                  </div>
                  <b-progress
                    variant="secondary"
                    :value="progress"
                    :max="100"
                    class="custom-height"
                  ></b-progress>
                </div>
                <div class="text-right mt-3">
                  <button
                    :disabled="this.progress !== 100"
                    class="btn btn-primary btn-sm"
                    @click.prevent="convertToDita()"
                  >
                    Convert Now
                  </button>
                </div>
              </form>
            </div>
            <div
              class="form-group"
              v-if="currentScreen === screens.convertToDita"
            >
              <div>
                <div class="custom-title text-left">
                  Converting to DITA<span class="text-danger">*</span>
                </div>
              </div>
              <form enctype="multipart/form-data">
                <!-- File drop zone -->
                <div class="showConvertbtn">
                  <div
                    v-if="fileSelected"
                    class="d-flex justify-content-between p-0 m-0"
                  >
                    <p class="text-break" v-if="fileName">{{ fileName }}</p>
                    <p class="text-secondary" v-if="progress === 100">
                      <span class="mdi mdi-check-circle-outline mr-1"></span
                      >Done
                    </p>
                  </div>
                  <b-progress
                    variant="secondary"
                    :value="progress"
                    :max="100"
                    class="custom-height"
                  ></b-progress>
                </div>
              </form>
            </div>
            <div
              class="form-group"
              v-if="currentScreen === screens.postFlightCheck"
            >
              <div>
                <div class="custom-title text-left">
                  Post-flight check file<span class="text-danger">*</span>
                </div>
              </div>
              <form enctype="multipart/form-data">
                <!-- File drop zone -->
                <div class="showConvertbtn">
                  <div
                    v-if="fileSelected"
                    class="d-flex justify-content-between p-0 m-0"
                  >
                    <p class="text-break" v-if="fileName">{{ fileName }}</p>

                    <p class="text-secondary" v-if="progress === 100">
                      <span class="mdi mdi-check-circle-outline mr-1"></span
                      >Done
                    </p>
                  </div>
                  <b-progress
                    variant="secondary"
                    :value="progress"
                    :max="100"
                    class="custom-height"
                  ></b-progress>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="d-flex justify-content-between">
        <div>
          <button
            v-if="DitaLog?.trim()"
            type="button"
            class="btn customBtn"
            v-b-tooltip.hover
            title="Download Log"
            id="save"
            variant="light"
            @click="downloadLogFile()"
          >
            <i class="fa-solid fa-circle-down"></i>
          </button>
        </div>
        <button
          class="btn btn-primary btn-sm custom-button"
          @click="gotoDownloadOutput()"
        >
          <span class="custom-icon">
            <i class="mdi mdi-folder-refresh mdi-16px"></i>
          </span>
          <span class="custom-text ml-2">View generated output</span>
        </button>
      </div>
      <div class="card mt-2">
        <div class="card-body">
          <div class="border-top pt-3 infoDiv" v-if="DitaLog?.trim()">
            <pre class="dita-log">{{ DitaLog }}</pre>
          </div>
          <p v-else>No errors found in the post flight check</p>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-progress"
      ref="modaloutputprogress"
      title="Processing"
      title-class="font-18"
      hide-header
      hide-footer
      hide-close
      no-close-on-backdrop
      no-close-on-esc
    >
      <strong>Please wait</strong>
      <br />
      <p>Document publishing is currently in progress.</p>
      <b-progress :value="progress" :max="100" animated></b-progress>
    </b-modal>
    <DocConPolicy />
    <PreFlightInvalidFile :files="invalidFiles" />
  </div>
</template>

<script>
import axios from "axios";
import Multiselect from "vue-multiselect";
import CryptoJS from "crypto-js";
import { secretKey } from "../../../../api/global.env";
import Steps from "./steps.vue";
import Swal from "sweetalert2";
import { serviceRoutes } from "../services/servicesRoutes";
import DocConPolicy from "./docconpolicy.vue";
import PreFlightInvalidFile from "./preflightinvalidfile.vue";

export default {
  components: {
    Multiselect,
    Steps,
    DocConPolicy,
    PreFlightInvalidFile,
  },
  props: {
    currentStep: {
      type: Number,
      required: true,
    },

    steps: {
      type: Array,
      required: true,
    },
  },
  emits: ["stepChanged"],

  data() {
    return {
      progress: 0,
      dragging: false,
      file: null,
      fileName: null,
      fileSelected: false,
      uploadPercentage: 0,
      showConvertbtn: false,

      policyCheckbox: false,
      screens: {
        default: "default",
        upload: "upload",
        preFlightCheck: "preFlightCheck",
        convertToDita: "convertToDita",
        postFlightCheck: "postFlightCheck",
      },
      currentScreen: "default",

      selectedFileType: null,
      migrationsOptions: [],
      fileExtensionsType: null,
      isLogGenerated: false,
      DitaLog: null,
      invalidFiles: [],
    };
  },

  methods: {
    messageToast(messageToastTitle, messageToastVariant, messageToastContent) {
      this.$bvToast.toast(messageToastContent, {
        title: messageToastTitle,
        variant: messageToastVariant,
        solid: true,
      });
    },

    handleDrop(e) {
      e.preventDefault();
      this.dragging = false;
      const file = e.dataTransfer.files[0];
      this.handleFileInput(file);
    },

    handleFileInput(file) {
      if (file) {
        const ext = file.name.split(".").pop();
        if (this.fileExtensionsType.includes(ext)) {
          this.file = file;
          this.fileName = file.name;
          this.fileSelected = true;
        } else {
          this.messageToast(
            "Error",
            "danger",
            "Please select a valid format file."
          );
          this.fileSelected = false;
        }
      }
    },

    openFileInput() {
      this.$refs.fileInput.click();
    },

    goBack(screen) {
      this.currentScreen = screen;
      this.$emit("stepChanged", this.currentStep - 1);
    },

    async uploadFileBackend() {
      this.currentScreen = this.screens.upload;
      this.progress = 0;
      const formData = new FormData();

      const selectedMigrationType = this.migrationsOptions.find(
        (opt) => opt.text === this.selectedFileType
      );

      formData.append(selectedMigrationType.sourceFile, this.file);
      const userId = this.$store.state.Auth.userId;
      const orgId = this.$store.state.Auth.orgId;

      try {
        const URL = serviceRoutes(
          userId,
          orgId,
          this.selectedFileType,
          "upload"
        );
        if (!URL) {
          this.messageToast(
            "Not Found",
            "danger",
            "This feature is not yet implemented."
          );
        }
        const response = await this.$store.getters.client.post(URL, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            this.progress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 90
            );
          },
        });
        this.progress = 100;

        this.fileSelected = true;
        this.messageToast(
          "Success",
          "primary",
          response?.data?.message || "Document uploaded successfully."
        );
        this.$emit("stepChanged", 3);
      } catch (error) {
        this.fileName = null;
        this.fileSelected = false;
        this.$emit("stepChanged", 1);
        this.currentScreen = this.screens.default;
        this.messageToast(
          "File validation failed",
          "danger",
          error.response.data.message
        );
      }
    },

    async preFlightCheck() {
      this.currentScreen = this.screens.preFlightCheck;
      this.progress = 0;
      try {
        const URL = `/orguser/docMigration/pre-flight-check?migrationType=${this.getMigrationType()}`;

        const progressInterval = setInterval(() => {
          if (this.progress < 97) {
            this.progress += 0.5;
          }
        }, 500);
        const response = await this.$store.getters.client.get(URL, {
          headers: {
            "Content-Type": "multipart/form-data",
          },

          onDownloadProgress: (progressEvent) => {
            clearInterval(progressInterval);
            this.progress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 97
            );
          },
        });
        if (response?.data?.message) {
          clearInterval(progressInterval);
          this.progress = 100;
        }
        this.messageToast("Success", "primary", response?.data?.message);
        this.$emit("stepChanged", 4);
      } catch (error) {
        this.fileName = null;
        this.fileSelected = false;
        this.$emit("stepChanged", 1);
        this.currentScreen = this.screens.default;
        this.invalidFiles = error?.response?.data?.data || [];
        if (this.invalidFiles.length > 0) {
          this.$bvModal.show("pre-flight-invalid-file");
        }
        this.messageToast(
          "File validation failed",
          "danger",
          error.response.data.message ?? "Invalid request"
        );
      }
    },

    async convertToDita() {
      this.currentScreen = this.screens.convertToDita;
      this.progress = 0;
      const userId = this.$store.state.Auth.userId;
      const orgId = this.$store.state.Auth.orgId;
      try {
        const URL = serviceRoutes(
          userId,
          orgId,
          this.selectedFileType,
          "transform"
        );

        if (!URL) {
          this.messageToast(
            "Not Found",
            "danger",
            "This feature is not yet implemented."
          );
          return;
        }

        localStorage.removeItem("userActivityId");

        const response = await this.$store.getters.client.post(
          URL,
          {},
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) => {
              this.progress = Math.round(
                (progressEvent.loaded / progressEvent.total) * 90
              );
            },
          }
        );
        this.progress = 100;

        this.messageToast(
          "Success",
          "primary",
          response?.data?.message || "converting to DITA done successfully."
        );

        localStorage.setItem("userActivityId", response?.data?.userActivityId);

        this.$emit("stepChanged", 5);
        Swal.fire({
          icon: "question",
          title: "View Warning and Error Logs",
          showCancelButton: true,
          confirmButtonText: "Verify Now",
          cancelButtonText: "No",
        }).then((response) => {
          if (response.isConfirmed) {
            this.progress = 0;
            this.currentScreen = this.screens.postFlightCheck;
            this.postFlightCheck();
          } else {
            const downloadLink = serviceRoutes(
              userId,
              orgId,
              this.selectedFileType,
              "download"
            );

            this.$router.push({
              path: `/docmigration/outputscreen/${encodeURIComponent(
                CryptoJS.AES.encrypt(downloadLink, secretKey).toString()
              )}/${encodeURIComponent(
                CryptoJS.AES.encrypt(this.fileName, secretKey).toString()
              )}/${encodeURIComponent(
                CryptoJS.AES.encrypt(
                  this.getMigrationType(),
                  secretKey
                ).toString()
              )}`,
            });
          }
        });
      } catch (error) {
        this.fileName = null;
        this.fileSelected = false;
        this.$emit("stepChanged", 1);
        this.currentScreen = this.screens.default;
        this.messageToast(
          "Invalid request",
          "danger",
          error.response.data.message
        );
      }
    },

    async postFlightCheck() {
      this.progress = 0;

      try {
        const URL = `/orguser/docMigration/post-flight-check?migrationType=${this.getMigrationType()}`;

        const progressInterval = setInterval(() => {
          if (this.progress < 97) {
            this.progress += 0.5;
          }
        }, 500);
        const response = await this.$store.getters.client.get(URL, {
          headers: {
            "Content-Type": "multipart/form-data",
          },

          onDownloadProgress: (progressEvent) => {
            clearInterval(progressInterval);
            this.progress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 97
            );
          },
        });
        if (response?.data?.message) {
          clearInterval(progressInterval);
          this.progress = 100;
        }
        this.DitaLog = response.data.fileContents;
        this.isLogGenerated = true;
        this.messageToast(
          "Success",
          "primary",
          response?.data?.message || "Post-flight check successful."
        );
        this.$emit("stepChanged", 6);
      } catch (error) {
        this.fileName = null;
        this.fileSelected = false;
        this.$emit("stepChanged", 1);
        this.currentScreen = this.screens.default;
        this.messageToast(
          "Invalid request",
          "danger",
          error.response.data.message
        );
      }
    },
    gotoDownloadOutput() {
      const userId = this.$store.state.Auth.userId;
      const orgId = this.$store.state.Auth.orgId;
      const downloadLink = serviceRoutes(
        userId,
        orgId,
        this.selectedFileType,
        "download"
      );

      this.$router.push({
        path: `/docmigration/outputscreen/${encodeURIComponent(
          CryptoJS.AES.encrypt(downloadLink, secretKey).toString()
        )}/${encodeURIComponent(
          CryptoJS.AES.encrypt(this.fileName, secretKey).toString()
        )}/${encodeURIComponent(
          CryptoJS.AES.encrypt(this.getMigrationType(), secretKey).toString()
        )}`,
      });
    },

    async downloadLogFile() {
      const downloadLink = `/orguser/docMigration/download-svrl-file?migrationType=${this.getMigrationType()}`;
      await this.$store.getters.client
        .get(downloadLink, {
          responseType: "blob",
        })
        .then((response) => {
          const generateName = (fileName) => {
            const lastDotPosition = fileName.lastIndexOf(".");
            const nameWithoutExtension = fileName.substring(0, lastDotPosition);
            return nameWithoutExtension + "_log";
          };
          const downloadFilename = generateName(this.fileName);

          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", downloadFilename);
          document.body.appendChild(link);
          link.click();
          this.messageToast("Success", "primary", "Downloaded successfull!");
        })
        .catch((error) => {
          this.messageToast("Invalid request", "danger", error.message);
        });
    },

    fixErrorAndWarning() {
      Swal.fire({
        icon: "question",
        title: "Do you want to fix these errors and warnings?",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then(async (response) => {
        if (response.isConfirmed) {
          this.progress = 0;
          this.$refs["modaloutputprogress"].show();

          try {
            await axios.post(
              "https://jsonplaceholder.typicode.com/posts",
              {
                title: "foo",
                body: "bar",
                userId: 1,
              },
              {
                onUploadProgress: (progressEvent) => {
                  this.progress = Math.round(
                    (progressEvent.loaded / progressEvent.total) * 90
                  );
                },
              }
            );
            this.progress = 100;
            this.$refs["modaloutputprogress"].hide();
            this.messageToast(
              "Success",
              "primary",
              "Errors and warnings are fixed successfully."
            );
          } catch (error) {
            this.messageToast(
              "Invalid request",
              "danger",
              error.response.data.message
            );
          }
        }
      });
    },

    cancelForm() {
      this.file = null;
      this.fileName = null;
      this.dragging = false;
      this.fileSelected = false;
    },

    // steps methods
    emitNextStep() {
      this.$emit("nextStep");
    },
    emitLastStep() {
      this.$emit("lastStep");
    },
    emitStepChanged() {
      if (!this.selectedFileType) {
        this.$emit("stepChanged", 1);
      } else {
        this.$emit("stepChanged", 2);
        this.currentScreen = this.screens.default;
      }
    },
    stepWrapperClass(step) {
      return {
        active: step === this.currentStep,
        complete: step < this.currentStep,
      };
    },

    getMigrationType() {
      const selectedMigrationType = this.migrationsOptions.find(
        (opt) => opt.text === this.selectedFileType
      );
      return selectedMigrationType.migrationType;
    },

    async getMigrationsOptions() {
      const response = await this.$store.getters.client.get(
        `/orguser/docMigration/get-docMigrationType-available `
      );
      this.migrationsOptions = response.data.data;
    },
  },

  mounted() {
    this.getMigrationsOptions();
  },

  watch: {
    selectedFileType: {
      handler() {
        this.migrationsOptions.forEach((option) => {
          if (option.text === this.selectedFileType) {
            this.fileExtensionsType = option.type;
          }
        });
      },
    },
  },
};
</script>

<style scoped>
.custom-title {
  font-size: 16 px;
  font-weight: 400;
  line-height: 18.77px;
  letter-spacing: 0.5px;
  text-align: left;
  color: rgba(23, 35, 61, 1);
  margin-bottom: 10px;
}
.drag-and-drop-text {
  font-weight: 500 !important;

  align-items: center;
  font-size: 20px !important;
}

.accepted-doc-type {
  font-size: 16px !important;
  font-weight: 400 !important;
}
p {
  margin-top: 0;
  margin-bottom: 0.3rem !important;
}
.drop-zone {
  border: 1.5px dashed rgba(15, 52, 96, 1);
  background-color: rgba(113, 165, 203, 0.05);
  display: flex;
  min-height: 200px;
  padding: 20px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.drop-zone p.mt-2 {
  margin-top: 0.5rem;
  font-size: 20px;
}
.drop-zone p.m-0 {
  font-size: 16px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.5px;
  text-align: center;
}
.alert-success {
  margin: 0.5rem 0;
}
.btn-secondaryBtn {
  background: #252b3b !important;
  color: #fff !important;
}
.card-icons-btn {
  background: #5864d2;
  color: #fff;
}
.card-icons-btn:hover {
  color: #fff;
}
.progress {
  height: 20px;
}
.progress-bar {
  background-color: rgba(254, 94, 69, 1) !important;
}
.showConvertbtn {
  padding: 0.2rem !important;
  background-color: rgba(220, 222, 226, 0.2);
}
.custom-height {
  height: 5px;
}
.dita-log {
  white-space: pre-wrap; /* CSS3 */
  white-space: -moz-pre-wrap; /* Mozilla */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* IE */
  overflow-wrap: break-word;
  max-height: 70vh;
  overflow: auto;
}
</style>
