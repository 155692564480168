var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"align-items-stretch row"},[_vm._l((_vm.cardData),function(data,index){return _c('div',{key:index,staticClass:"col-12 col-sm-4 mb-3"},[_c('router-link',{attrs:{"to":data.link}},[_c('div',{staticClass:"card mb-0 h-100"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"custom-title mb-0"},[_vm._v(_vm._s(data.name))]),_c('div',{staticClass:"d-flex align-items-start justify-content-between mt-3"},[_c('span',{staticClass:"mdi d-flex align-items-center justify-content-center",class:data.icon,style:({
                color: data.color,
                fontSize: '25px',
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                backgroundColor: `${data.color}42`,
              })}),_c('h2',{staticClass:"mb-0",staticStyle:{"font-size":"30px","font-weight":"300"}},[_vm._v(" "+_vm._s(_vm.formatNumber(data.value))+" ")])])])])])],1)}),_c('div',{staticClass:"col-12 col-sm-4 mb-3"},[_c('router-link',{attrs:{"to":"/usage"}},[_c('div',{staticClass:"card mb-0 h-100"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"custom-title mb-0"},[_vm._v("Credits Usage")]),_c('div',{staticClass:"d-flex align-items-start justify-content-between mt-3"},[_c('div',[_c('p',{staticClass:"mb-0",staticStyle:{"font-size":"14px","font-weight":"300"}},[_c('b',[_vm._v("Used: ")]),_vm._v(" "+_vm._s(_vm.formatNumber(_vm.creditsDetails?.totalCreditsUsed))+" ")]),(_vm.creditsDetails.remainingCredits >= 0)?_c('p',{staticClass:"mb-0",staticStyle:{"font-size":"14px","font-weight":"300"}},[_c('b',[_vm._v("Remaining: ")]),_vm._v(" "+_vm._s(_vm.formatNumber(_vm.creditsDetails.remainingCredits))+" ")]):_vm._e()])])])])])],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }