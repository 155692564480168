<template>
  <div>
    <div>
      <div
        class="custom-notifications d-flex justify-content-end align-items-center"
      >
        <div>
          <button
            class="btn text-primary view-details-btn btn-sm m-0"
            @click="viewMore"
          >
            <span class="mdi mdi-eye-outline mr-2"></span> View More
          </button>
        </div>
      </div>
      <!-- Main Card -->
      <div class="row">
        <div class="col-12">
          <div class="">
            <div class="">
              <!-- Filter and Per Page Options -->
              <div class="row mt-2 d-flex justify-content-between mx-3">
                <!-- Data Table -->
                <div class="table-responsive mb-0">
                  <b-table
                    :items="activityData"
                    :fields="colfields"
                    responsive="sm"
                    ref="adminTable"
                    show-empty
                  >
                    <!-- Custom cell rendering for createdAt -->
                    <template #cell(createdAt)="row">
                      {{
                        moment(row.item.createdAt).format("YYYY-MM-DD h:mm A")
                      }}
                    </template>

                    <template #cell(download)="row">
                      <b-button
                        size="sm"
                        variant="primary"
                        @click="downloadFun(row.item)"
                        ><i class="mdi mdi-download"></i
                      ></b-button>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import _ from "lodash";
import { eventBus } from "../../../../main";
import downloadSourceFiles from "../../../../services/download-source-files";

export default {
  data() {
    return {
      activityData: [],
      userId: this.$store.state.Auth.userId,
      gitToken: this.$store.state.Auth.gitToken,
      colfields: this.generateTableColumns(),
    };
  },
  computed: {
    _() {
      return _;
    },
  },

  created() {
    eventBus.$emit("update-sidebar", "menuitems.dashboard.text");
    if (this.$store.state.Auth.recentPublicationsData.length) {
      this.activityData = this.$store.state.Auth.recentPublicationsData.splice(
        0,
        10
      );
    } else {
      this.getReleasePublication();
    }
  },

  methods: {
    viewMore() {
      this.$router.push({
        name: "Releasetable",
      });
    },
    moment,

    generateTableColumns() {
      return [
        {
          key: "releaseTitle",
          label: "Release Title",
          sortable: true,
        },
        {
          key: "projectName",
          label: "Project Name",
          sortable: true,
        },

        {
          key: "outputFormat",
          label: "Output Format",
          sortable: true,
        },
        {
          key: "ditaotVersion",
          label: "DITA OT Version",
          sortable: true,
        },
        {
          key: "createdAt",
          label: "Created At",
          sortable: true,
          sortByFormatted: true,
          formatter: (value, key, item) =>
            moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss"),
        },

        {
          key: "download",
          label: "Download",
          sortable: false,
        },
      ];
    },
    // This method is use to generate error or success message
    messageToast(messageToastTitle, messageToastVariant, messageToastContent) {
      this.$bvToast.toast(messageToastContent, {
        title: messageToastTitle,
        variant: messageToastVariant,
        solid: true,
      });
    },

    getReleasePublication() {
      this.$store.getters.client
        .get(`/orguser/release/byuserId?userId=${this.userId}`)
        .then((response) => {
          if (response.data) {
            this.activityData = response.data.splice(0, 10);
            this.$store.commit("setRecentPublicationsData", response.data);
          } else {
            this.messageToast(
              "Invalid request",
              "danger",
              "No data received from the server"
            );
          }
        })
        .catch((err) => {
          this.messageToast(
            "Invalid request",
            "danger",
            err.response ? err.response.data.message : "An error occurred"
          );
          this.showSpinner = false;
        });
    },

    async downloadFun(item) {
      console.log("📢[releasetable.vue:389]: item: ", item);

      let loader = this.$loading.show({
        loader: "dots",
      });
      try {
        await downloadSourceFiles({
          repoName: item.projectName,
          commitSHA: item.commitSHA,
        });
        this.messageToast(
          "Files downloaded",
          "primary",
          "Downloaded successful!"
        );
      } catch (error) {
        this.messageToast("Invalid request", "danger", error.message);
      } finally {
        loader.hide();
      }
    },
  },
};
</script>

<style scoped>
.custom-input-box {
  border: 1px solid #ced4da !important;
  border-radius: 0px;
  background-color: #ffffff !important;
}

.export-button {
  height: 38px;
}

.search-box input {
  height: 38px;
}

.view-details-btn {
  background-color: rgba(113, 165, 203, 0.2);
}

.custom-notifications {
  padding: 14px;
  gap: 24px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .row-count-cust-cls {
    margin-bottom: 10px;
  }

  .row-pagination-cust-cls {
    margin-top: 20px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .row-count-cust-cls ul {
    margin-bottom: 10px;
    text-align: left !important;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
</style>
